import React from 'react';
import { HashRouter as Router, Route, Link } from "react-router-dom";
import Home from './containers/Home';
import logo from './gfx/labben-orange.svg';
import './App.css';

const App = () => (
    <Router>
        <div>

            <header className="App-header">
                <nav>
                    <ul>
                        <li id="statsContainer">
                            <Link to="/">Statistikk</Link>
                        </li>

                        <li id="aboutContainer">
                            <Link to="/about">Om denne siden</Link>
                        </li>
                    </ul>
                </nav>

            </header>


            <Route exact path="/" component={HomeContainer} />
            <Route path="/about" component={AboutComponent} />

            <footer className="footer">
                Mer informasjon om koronaviruset (Covid-19) hos <a href="https://www.fhi.no/" rel="noopener noreferrer" target="_blank">Folkehelseinstituttet</a>.
                <div><img alt="Labben logo" src={logo}/></div>
            </footer>

        </div>
    </Router>
);


const HomeContainer = () => (
    <Home/>
);

const AboutComponent = () => (
    <div className="about-content content-container">
        <p>Teknologi er gøy. Hvorfor ikke?</p>
        <p>Antibac-dispenserene har ESP8266 mikrokontroller tilkoblet en knapp. Knappen er festet i egendesignet adapter som er festet oppå trykktuten på antibac-flasken.</p>
        <p>Mer info kommer...</p>
    </div>
);

export default App;
