import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
//import './PromotedStats.css';

class Home extends Component {

    constructor(props){
        super(props);

        this.state = {
            errorMessage: '',
            chartData: []
        }

    }

    fetchAllDispenses(){
        var that = this;
        if(window.location.hostname == 'localhost'){
            var data = [{"floor":"6","dispenses":"8"},{"floor":"5","dispenses":"3"}];
            const chartData = [];
            data.map(function(item, index){
                let floorItem = [item.floor + '. etasje', parseInt(item.dispenses)];
                chartData.push(floorItem);
            });
            that.setState({errorMessage : "", chartData: {name: '# of dispenses', data: chartData}, dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',

                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }});
        }else{
            fetch('/api/getStats/dispensesByFloors/')
                .then((response) => response.json())
                .then(function(data){
                        const chartData = [];
                        data.map(function(item, index){
                            let floorItem = [item.floor + '. etasje', parseInt(item.dispenses)];
                            chartData.push(floorItem);
                        });
                        console.log(chartData);
                        that.setState({errorMessage : "", chartData: {name: '# of dispenses', data: chartData}, dataLabels: {
                                enabled: true,
                                rotation: -90,
                                color: '#FFFFFF',
                                align: 'right',
                                format: '{point.y:.1f}', // one decimal
                                y: 10, // 10 pixels down from the top
                                style: {
                                    fontSize: '13px',
                                    fontFamily: 'Verdana, sans-serif'
                                }
                            }});

                }).catch(function(e){
                    console.log(e);
                    that.setState({errorMessage : "Åånei! Vi klarer faktisk ikke å hente statistikken :/ Prøv igjen senere!"});
            });
        }
    }

    componentDidMount(){
        this.fetchAllDispenses();
    }

    render() {

        const options = {
            chart: {
                type: 'column',
                backgroundColor: null
            },
            title: {
                text: 'antall trykk per etasje',
                style: {
                    color: '#FFFFFF'
                }
            },
            legend: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif',
                        color: '#FFFFFF',
                    }
                }
            },
            yAxis: {
                min: 0,
                color: '#FFFFFF',
                title: {
                    text: '# of dispenses',
                    style: {
                        color: '#FFFFFF'
                    },
                },
                labels: {
                    style: {
                        color: '#FFFFFF'
                    }
                }
            },
            series: this.state.chartData
        }

        return(
            <div className="home-content content-container">
                {this.state.errorMessage}
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        );
    }
}

export default Home;